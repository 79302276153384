import {Transition} from '@headlessui/react'
import React, {useRef, useState} from 'react'
import {useClickOutside} from '../../hooks'
import {DotsVerticalIcon} from '../icon'

export const MenuItem: React.FC<React.HTMLAttributes<HTMLAnchorElement>> = ({children, ...props}) => {
  return (
    <a
      className="block group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
      role="menuitem"
      {...props}
    >
      {children}
    </a>
  )
}

export const MenuDivider: React.FC = () => {
  return <div className="border-t border-gray-200" />
}

export interface MenuProps extends React.HTMLAttributes<HTMLElement> {
  size?: 'tiny' | 'small' | 'normal' | 'large'
}

export const Menu: React.FC<MenuProps> = ({children, id, size = 'normal', ...props}) => {
  let buttonSize: string

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const ref = useRef()
  useClickOutside(ref, () => setIsOpen(false))

  const handleClick = () => {
    setIsOpen((v) => !v)
  }

  switch (size) {
    case 'tiny':
      buttonSize = 'h-4 w-4'
      break
    case 'small':
      buttonSize = 'h-5 w-5'
      break
    case 'normal':
      buttonSize = 'h-6 w-6'
      break
    case 'large':
      buttonSize = 'h-7 w-7'
      break

    default:
      break
  }

  return (
    <div className="relative inline-block text-left" {...props}>
      <div>
        <button
          ref={ref}
          type="button"
          className="flex items-center text-gray-500 hover:text-gray-800 focus:outline-none focus:text-gray-800"
          aria-label="Options"
          id={id}
          aria-haspopup="true"
          aria-expanded="true"
          onClick={handleClick}
        >
          <DotsVerticalIcon className={buttonSize} />
        </button>
      </div>

      <Transition
        show={isOpen}
        className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg z-20"
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="rounded-md bg-white ring-1 ring-black ring-opacity-10">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby={id}>
            {children}
          </div>
        </div>
      </Transition>
    </div>
  )
}
