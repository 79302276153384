/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AppointmentImportStatus {
  COMPLETE = "COMPLETE",
  PENDING = "PENDING",
  WAITING = "WAITING",
}

export enum Role {
  ADMIN = "ADMIN",
  ASSISTANT = "ASSISTANT",
  DOCTOR = "DOCTOR",
  FIRST_AVAILABLE = "FIRST_AVAILABLE",
  HYGIENE = "HYGIENE",
  NON_USER = "NON_USER",
  OFFICE = "OFFICE",
  TEAM = "TEAM",
  TREATMENT = "TREATMENT",
}

export enum RuleColor {
  GREEN = "GREEN",
  RED = "RED",
  YELLOW = "YELLOW",
}

export enum StatMetric {
  AVG_COMPLETE_DELTA = "AVG_COMPLETE_DELTA",
  AVG_LENGTH = "AVG_LENGTH",
  AVG_SEATED_DELTA = "AVG_SEATED_DELTA",
  AVG_SERVE_TIME = "AVG_SERVE_TIME",
  AVG_WAIT_TIME = "AVG_WAIT_TIME",
  PERCENT_COMPLETE_EARLY = "PERCENT_COMPLETE_EARLY",
  PERCENT_COMPLETE_LATE = "PERCENT_COMPLETE_LATE",
  PERCENT_COMPLETE_ON_TIME = "PERCENT_COMPLETE_ON_TIME",
  PERCENT_SEATED = "PERCENT_SEATED",
  PERCENT_SEATED_LATE = "PERCENT_SEATED_LATE",
  PERCENT_SEATED_ON_TIME = "PERCENT_SEATED_ON_TIME",
  PERCENT_SEATED_VERY_LATE = "PERCENT_SEATED_VERY_LATE",
  TOTAL_PATIENTS_SERVED = "TOTAL_PATIENTS_SERVED",
}

export enum Status {
  CLAIMED = "CLAIMED",
  COMPLETE = "COMPLETE",
  DELETED = "DELETED",
  PENDING = "PENDING",
  SEATED = "SEATED",
  WAITING = "WAITING",
}

export interface AppointmentEventInput {
  appointmentId: string;
  patient?: string | null;
  locationId?: string | null;
  requestingId?: string | null;
  scheduled?: number | null;
  length?: number | null;
  important?: boolean | null;
  notes?: string | null;
  status?: Status | null;
  tagIds?: string[] | null;
  providerId?: string | null;
  roomId?: string | null;
  createdAt?: number | null;
  createdById?: string | null;
  parentId?: string | null;
}

export interface AppointmentInput {
  patient: string;
  locationId: string;
  scheduled: number;
  length?: number | null;
  important: boolean;
  tid?: string | null;
  createdById?: string | null;
  importHash?: string | null;
  notes?: string | null;
  tagIds?: string[] | null;
  providerId?: string | null;
  roomId?: string | null;
  requestingId?: string | null;
  createdAt?: number | null;
  parentId?: string | null;
}

export interface AppointmentModificationInput {
  appointmentId: string;
  patient?: string | null;
  locationId?: string | null;
  requestingId?: string | null;
  scheduled?: number | null;
  length?: number | null;
  important?: boolean | null;
  notes?: string | null;
  status?: Status | null;
  tagIds?: string[] | null;
  providerId?: string | null;
  roomId?: string | null;
  createdAt?: number | null;
  createdById?: string | null;
  startTime?: number | null;
  endTime?: number | null;
}

export interface DateRange {
  startDate: any;
  endDate: any;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
