/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable react/no-array-index-key */
import React, {PropsWithChildren, ReactElement} from 'react'
import {
  DragDropContext,
  DropResult,
  Droppable,
  DroppableProvided,
  Draggable,
  DraggableProvided,
} from 'react-beautiful-dnd'

export interface Column<T> {
  name?: string
  value: (item: T) => unknown
}

export interface TableProps<T> {
  items: T[]
  columns: Column<T>[]
  draggable?: boolean
  onDragEnd?: (result: DropResult) => void
}

export const Table = <T,>({
  items,
  columns,
  draggable,
  onDragEnd,
  ...props
}: PropsWithChildren<TableProps<T>>): ReactElement | null => {
  return (
    <div className="flex flex-col" {...props}>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 bg-white">
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th
                      key={`${column.name}-${index}`}
                      className="px-6 py-3 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {column.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable-table" isDropDisabled={!draggable}>
                  {(provided: DroppableProvided): React.ReactElement => (
                    <tbody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="bg-white divide-y divide-gray-200"
                    >
                      {items.map((item, index) => (
                        <Draggable
                          key={index}
                          draggableId={`draggable-${index}`}
                          index={index}
                          isDragDisabled={!draggable}
                        >
                          {(providedDraggable: DraggableProvided): React.ReactElement => (
                            <tr
                              ref={providedDraggable.innerRef}
                              {...providedDraggable.draggableProps}
                              {...providedDraggable.dragHandleProps}
                              className="bg-white"
                            >
                              {columns.map((column, index) => (
                                <td
                                  key={`${column.name}-td-${index}`}
                                  className="px-6 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900"
                                >
                                  {column.value(item)}
                                </td>
                              ))}
                            </tr>
                          )}
                        </Draggable>
                      ))}

                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
