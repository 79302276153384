import React, {useState} from 'react'
import {Modal, Button} from '../tailwind'

export interface ConfirmDialogProps {
  title?: string
  subtitle?: string
  onDismiss: (result: boolean) => Promise<void>
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({title, subtitle, children, onDismiss}) => {
  const [busy, setBusy] = useState(false)

  const handleDismiss = async () => {
    setBusy(true)
    await onDismiss(false)
    setBusy(false)
  }

  const handleConfirm = async () => {
    setBusy(true)
    await onDismiss(true)
    setBusy(false)
  }

  const handleSubmit = (e: React.FormEvent | React.MouseEvent) => {
    e.preventDefault()
    handleConfirm()
  }

  return (
    <Modal onDismiss={handleDismiss}>
      <form onSubmit={handleSubmit}>
        <div className="space-y-3">
          <div className="sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
            <div className="w-full">
              <h3 className="text-lg leading-6 font-medium text-gray-900">{title ?? 'Confirm'}</h3>
              {subtitle && <p className="mt-1 text-sm leading-5 text-gray-600">{subtitle}</p>}
            </div>
          </div>
          {children}
        </div>
        <div className="mt-8 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <Button
            autoFocus
            fullWidth
            secondary
            disabled={busy}
            busy={busy}
            className="mt-3 sm:mt-0 sm:col-start-1"
            onClick={handleDismiss}
          >
            No
          </Button>
          <Button
            fullWidth
            disabled={busy}
            busy={busy}
            type="submit"
            className="sm:col-start-2"
            onClick={handleConfirm}
          >
            Yes
          </Button>
        </div>
      </form>
    </Modal>
  )
}
