import React from 'react'
import {Icon, XCircleIconSmall, ExclamationIconSmall, InfoCircleIconSmall, CheckCircleIconSmall} from '../icon'

export enum BannerType {
  SUCCESS,
  WARNING,
  ERROR,
  INFO,
}

export interface BannerOptions extends React.HTMLAttributes<HTMLElement> {
  title?: string
  content: string
  type?: 'success' | 'error' | 'warning' | 'info'
}

export const Banner: React.FC<BannerOptions> = ({title, content, type, ...props}) => {
  let BannerIcon: Icon
  let backgroundColor: string
  let iconColor: string
  let titleColor: string
  let bodyColor: string
  switch (type) {
    case 'success':
      BannerIcon = CheckCircleIconSmall
      backgroundColor = 'bg-green-50'
      iconColor = 'text-green-400'
      titleColor = 'text-green-800'
      bodyColor = 'text-green-700'
      break
    case 'warning':
      BannerIcon = ExclamationIconSmall
      backgroundColor = 'bg-yellow-50'
      iconColor = 'text-yellow-400'
      titleColor = 'text-yellow-800'
      bodyColor = 'text-yellow-700'
      break
    case 'error':
      BannerIcon = XCircleIconSmall
      backgroundColor = 'bg-red-50'
      iconColor = 'text-red-400'
      titleColor = 'text-red-800'
      bodyColor = 'text-red-700'
      break
    case 'info':
    default:
      BannerIcon = InfoCircleIconSmall
      backgroundColor = 'bg-blue-50'
      iconColor = 'text-blue-400'
      titleColor = 'text-blue-800'
      bodyColor = 'text-blue-700'
      break
  }

  return (
    <div {...props}>
      <div className={`rounded-md ${backgroundColor} p-4`}>
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <BannerIcon className={`h-5 w-5 ${iconColor}`} />
          </div>
          <div className="ml-3 space-y-2">
            {title && <h3 className={`text-sm leading-5 font-medium ${titleColor}`}>{title}</h3>}
            <div className={`text-sm leading-5 ${bodyColor}`}>
              <p>{content}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
