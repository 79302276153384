/* eslint-disable react/no-array-index-key */
import {Link} from 'gatsby'
import React, {HTMLAttributes, useState, useEffect} from 'react'
import {Button} from '.'
import {ChevronLeftIconSmall, ChevronRightIconSmall, PencilIconSmall} from '../icon'

export interface Breadcrumb {
  name: string
  path: string
}

export interface PageHeadingProps extends HTMLAttributes<HTMLElement> {
  title: string
  editable?: boolean
  placeholder?: string
  onEdited?: (value: string) => void
  breadcrumbs?: Breadcrumb[]
}

export const PageHeading: React.FC<PageHeadingProps> = ({
  title,
  breadcrumbs,
  editable,
  placeholder,
  onEdited,
  children,
  className,
  ...props
}) => {
  const [editing, setEditing] = useState(false)
  const [name, setName] = useState(title)

  const {path: backPath} = breadcrumbs?.slice(-1)[0] ?? {}

  useEffect(() => {
    if (!editable) setName(title)
  }, [editable, title])

  const handleEditClick = () => {
    // if (onEdited) onEdited('test')
    setEditing(true)
  }

  const handleSave = (e: React.FormEvent | React.MouseEvent) => {
    e.preventDefault()
    if (onEdited) onEdited(name)
    setEditing(false)
  }

  const handleCancelClick = () => {
    setEditing(false)
    setName(title)
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  return (
    <div className={`${className} mb-8`} {...props}>
      {breadcrumbs && (
        <div>
          <nav className="sm:hidden">
            <Link
              to={backPath}
              className="flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out"
            >
              <ChevronLeftIconSmall className="w-5 h-5 text-gray-400" />
              Back
            </Link>
          </nav>
          <nav className="hidden sm:flex items-center text-sm leading-5 font-medium">
            {breadcrumbs.map(({name, path}, index) => (
              <React.Fragment key={index}>
                <Link to={path} className="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">
                  {name}
                </Link>
                <ChevronRightIconSmall className="w-5 h-5 text-gray-400" />
              </React.Fragment>
            ))}
            <span className="text-gray-500 cursor-default">{name}</span>
          </nav>
        </div>
      )}
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="flex items-center space-x-2 min-w-0">
          {editing ? (
            <form onSubmit={handleSave}>
              <input
                autoFocus
                type="text"
                className="border-gray-300 rounded-md block w-full text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate bg-transparent p-0"
                value={name}
                placeholder={placeholder || 'Untitled'}
                onChange={handleNameChange}
              />
            </form>
          ) : (
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">{name}</h2>
          )}
          {editable && !editing && <Button icon={PencilIconSmall} onClick={handleEditClick} />}
          {editing && (
            <div className="flex items-center space-x-2">
              <Button size="small" onClick={handleSave}>
                Save
              </Button>
              <Button secondary size="small" onClick={handleCancelClick}>
                Cancel
              </Button>
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  )
}
