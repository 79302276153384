import React, {Dispatch} from 'react'
import {Location, User} from './models'

export type AppContextProps = {
  user?: User
  currentLocation?: Location

  setContext?: Dispatch<(prevState: AppContextProps) => AppContextProps>
}

export const AppContext = React.createContext<Partial<AppContextProps>>({})
