import React from 'react'
import {User} from '../../models'

export interface AvatarProps extends React.HTMLAttributes<HTMLElement> {
  user: User
  size?: 'tiny' | 'small' | 'normal' | 'large'
}

export const Avatar: React.FC<AvatarProps> = ({user, size = 'normal', ...props}) => {
  let sizeClass: string
  let textClass: string

  switch (size) {
    case 'tiny':
      sizeClass = 'h-5 w-5'
      textClass = 'text-xs'
      break
    case 'small':
      sizeClass = 'h-8 w-8'
      textClass = 'text-sm'
      break
    case 'large':
      sizeClass = 'h-12 w-12'
      textClass = 'text-lg'
      break
    case 'normal':
    default:
      sizeClass = 'h-10 w-10'
      textClass = 'text-xl'
      break
  }

  return user.picture ? (
    <img src={user.picture} alt={user.name} className={`flex-shrink-0 ${sizeClass} rounded-full`} {...props} />
  ) : (
    <span className={`inline-flex items-center justify-center ${sizeClass} rounded-full bg-gray-400`} {...props}>
      <span className={`${textClass} font-medium leading-none text-white uppercase`}>{user.name.charAt(0)}</span>
    </span>
  )
}
