import React from 'react'

import {CheckIconSmall} from '../icon'
import Spinner from '../spinner'
import {useSelect} from 'downshift'
import {SelectArrows} from './select-arrows'

export interface SelectProps<T extends Record<string, unknown>> extends React.HTMLAttributes<HTMLSelectElement> {
  value: string
  display?: string
  visual?(value: T): React.ReactNode
  items: T[]
  initial?: T
  onSelection(value: T): void
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Select = <T extends Record<string, unknown>>({
  value,
  display,
  visual,
  items,
  onSelection,
  initial,
  className,
  ...props
}: SelectProps<T>) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    // getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect<T>({
    items: items ?? [],
    itemToString: (item) => (item ? String(item[display ?? value]) : ''),
    onSelectedItemChange: (changes) => onSelection(changes.selectedItem),
    initialSelectedItem: initial,
  })

  const getDisplay = (item: T): string => item[display ?? value]?.toString()

  return (
    <div className="space-y-1 w-full">
      <div className="relative">
        <span className="inline-block w-full rounded-md shadow-sm">
          <button
            {...props}
            type="button"
            className={`${className} relative w-full bg-white border rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default sm:text-sm border-gray-300 focus-within:outline-none focus-within:ring-1 focus-within:ring-indigo-500 focus-within:border-indigo-500`}
            {...getToggleButtonProps()}
          >
            {selectedItem ? (
              <div className="flex items-center space-x-3">
                {visual && visual(selectedItem)}
                <span className="block truncate">{getDisplay(selectedItem)}</span>
              </div>
            ) : items ? (
              <div className="flex items-center">
                <span className="block h-5"> </span>
              </div>
            ) : (
              <Spinner className="h-5 w-5" />
            )}
            <SelectArrows />
          </button>
        </span>

        <ul className="absolute mt-1 w-full rounded-md bg-white shadow-lg focus:outline-none z-50" {...getMenuProps()}>
          {isOpen && (
            <div className="max-h-60 rounded-md py-1 text-base leading-6 ring-1 ring-black ring-opacity-10 overflow-auto sm:text-sm sm:leading-5">
              {items &&
                items.map((item, index) => (
                  <li
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${item[value]?.toString()}${index}`}
                    className={`${
                      highlightedIndex === index ? 'bg-secondary text-white' : 'text-gray-900'
                    } cursor-default select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-secondary focus:text-white focus:bg-secondary`}
                    {...getItemProps({item, index})}
                  >
                    <div className="flex items-center space-x-3">
                      {visual && visual(item)}
                      <span className={`${item === selectedItem ? 'font-semibold' : 'font-normal'} block truncate`}>
                        {getDisplay(item)}
                      </span>
                    </div>

                    {item === selectedItem && (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                        <CheckIconSmall className="h-5 w-5" />
                      </span>
                    )}
                  </li>
                ))}
            </div>
          )}
        </ul>
      </div>
    </div>
  )
}
