import {useRef, useEffect, RefObject, useCallback, useState} from 'react'

export const isSSR = !(typeof window !== 'undefined' && window.document?.createElement)

export const getElement = <T = undefined>(
  element?: RefObject<Element> | Element | null | T
): Element | null | undefined | T =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  element && 'current' in element ? element.current : element

interface Props {
  type: keyof WindowEventMap
  listener: EventListener
  element?: RefObject<Element> | Document | Window
  options?: AddEventListenerOptions
}

export const useEventListener = ({type, listener, element = isSSR ? undefined : window, options}: Props): void => {
  const savedListener = useRef<EventListener>()

  useEffect(() => {
    savedListener.current = listener
  }, [listener])

  const handleEventListener = useCallback((event: Event) => savedListener.current?.(event), [])

  useEffect(() => {
    const target = getElement(element)

    // eslint-disable-next-line chai-friendly/no-unused-expressions
    target?.addEventListener(type, handleEventListener, options)
    return (): void => target?.removeEventListener(type, handleEventListener)
  }, [type, element, options, handleEventListener])
}

export const useClickOutside = (ref: RefObject<Element>, callback: (event: MouseEvent) => void): void => {
  const handleClick = useCallback(
    (event) => {
      if (!ref.current?.contains(event.target)) {
        callback(event)
      }
    },
    [callback, ref]
  )

  useEventListener({
    type: 'click',
    listener: handleClick,
  })
}

export const usePersistedState = <T>(key: string, initialValue?: T): [T, (value: T | ((val: T) => T)) => void] => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? (JSON.parse(item) as T) : initialValue
    } catch {
      // If error also return initialValue
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }

  return [storedValue, setValue]
}

/** keep typescript happy */
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useInterval(callback: () => unknown, delay: number | null | false, immediate?: boolean) {
  const savedCallback = useRef(noop)

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  })

  // Execute callback if immediate is set.
  useEffect(() => {
    if (!immediate) return
    if (delay === null || delay === false) return
    savedCallback.current()
  }, [immediate, delay])

  // Set up the interval.
  useEffect(() => {
    if (delay === null || delay === false) return undefined
    const tick = () => savedCallback.current()
    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}

export function useWindowSize(): {width: number; height: number} {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}
