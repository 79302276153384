import {Transition} from '@headlessui/react'
import React, {useCallback, useState} from 'react'

export interface ModalProps extends React.HTMLAttributes<HTMLElement> {
  size?: 'normal' | 'large'
  onDismiss: () => void
}

export const Modal: React.FC<ModalProps> = ({onDismiss, children, size = 'normal', ...props}) => {
  const [show, setShow] = useState(true)

  const handleDismiss = useCallback(() => {
    // Fade out...
    setShow(false)
    setTimeout(onDismiss, 200)
  }, [onDismiss])

  return (
    <Transition appear show={show}>
      <div className="fixed z-auto inset-0 overflow-y-auto" {...props}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity" onClick={handleDismiss}>
              <div className="absolute inset-0 bg-gray-500 opacity-75" />
            </div>
          </Transition.Child>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" /> &#8203;
            <div
              className={`inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle ${
                size === 'large' ? 'sm:max-w-4xl' : 'sm:max-w-2xl'
              } sm:w-full sm:p-6`}
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>
  )
}
