import React from 'react'

export interface FormElementProps extends React.HTMLAttributes<HTMLElement> {
  label: string
  hideLabel?: boolean
  required?: boolean
}

export const FormElement: React.FC<FormElementProps> = ({label, hideLabel, id, className, children, required}) => (
  <div className={className}>
    <label
      htmlFor={id}
      className={`${hideLabel && 'sr-only'} block mb-1 text-sm ${
        required ? 'font-bold' : 'font-medium'
      } leading-5 text-gray-700`}
    >
      {label}
      {required && '*'}
    </label>
    {children}
  </div>
)
