import React, {useState, useContext, useEffect, useCallback, HTMLAttributes} from 'react'
import {Link} from 'gatsby'
import {Transition} from '@headlessui/react'
import Auth from '@aws-amplify/auth'
// import AniLink from 'gatsby-plugin-transition-link/AniLink'

import {Avatar, Select} from './tailwind'
import {
  Icon,
  UsersIcon,
  TagIcon,
  ClipboardCheckIcon,
  LogoutIconSmall,
  LocationMarkerIcon,
  CollectionIcon,
  ChartBarIcon,
  CogIcon,
} from './icon'
import Logo from './logo'
import {AppContext} from '../context'
import {useQuery, gql} from '@apollo/client'
import {isAdmin, isProvider, Location, User} from '../models'
import {usePersistedState} from '../hooks'
import Spinner from './spinner'
import {LayoutLocationsQuery} from '../graphql/LayoutLocationsQuery'

const GET_LOCATIONS = gql`
  query LayoutLocationsQuery {
    locations {
      id
      name
      rooms {
        id
        name
        order
      }
    }
  }
`

const Main: React.FC = ({children, ...props}) => (
  <main {...props} className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex={0}>
    <div className="pt-2 pb-6 lg:py-6">
      <div className="px-4 sm:px-6 lg:px-8">
        {/* <!-- Replace with your content --> */}
        <div className="py-4  max-w-screen-lg">{children}</div>
        {/* <!-- /End replace --> */}
      </div>
    </div>
  </main>
)

const MenuOpenButton: React.FC<React.ComponentProps<'button'>> = (props) => (
  <div className="lg:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
    <button
      {...props}
      type="button"
      className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-600 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
      aria-label="Open sidebar"
    >
      <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
      </svg>
    </button>
  </div>
)

const MenuCloseButton: React.FC<React.ComponentProps<'button'>> = (props) => (
  <div className="absolute top-0 right-0 -mr-14 p-1">
    <button
      {...props}
      type="button"
      className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
      aria-label="Close sidebar"
    >
      <svg className="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
  </div>
)

interface NavLinkProps extends React.HTMLAttributes<HTMLElement> {
  href: string
  title: string
  Icon: Icon
}

const NavLink: React.FC<NavLinkProps> = ({href, title, Icon, className, ...props}) => (
  // <AniLink
  //   paintDrip
  //   hex="#86CEC1"
  //   duration={0.5}
  <Link
    {...props}
    to={href}
    activeClassName="text-gray-900 bg-gray-100"
    className={`${className} group flex items-center px-2 py-2 text-base font-medium rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:text-gray-900 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150`}
  >
    <Icon className="mr-4 lg:mr-3 h-6 w-6" />
    {title}
  </Link>
  // </AniLink>
)

interface NavProps extends HTMLAttributes<HTMLElement> {
  user: User
}

const Nav: React.FC<NavProps> = ({user, ...props}) => (
  <nav {...props} className="mt-5 px-2 space-y-1">
    <NavLink data-test="queue-link" href="/" title="Queue" Icon={CollectionIcon} />
    <NavLink data-test="complete-link" href="/complete/" title="Complete" Icon={ClipboardCheckIcon} />

    <div className="space-y-1">
      <h3 className="mt-8 px-3 text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider">Analytics</h3>
      {isProvider(user) && <NavLink href="/my-stats/" title="My Stats" Icon={ChartBarIcon} />}
      <NavLink href="/team-stats/" title="Team Stats" Icon={ChartBarIcon} />
    </div>

    {isAdmin(user) && (
      <div className="space-y-1">
        <h3 className="mt-8 px-3 text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider">Admin</h3>

        <NavLink href="/team/" title="Team" Icon={UsersIcon} />
        <NavLink href="/locations/" title="Locations" Icon={LocationMarkerIcon} />
        <NavLink href="/tags/" title="Tags" Icon={TagIcon} />
        <NavLink href="/settings/" title="Settings" Icon={CogIcon} />
      </div>
    )}
  </nav>
)

const handleSignout = (): void => {
  Auth.signOut()
}

const Sidebar: React.FC = () => {
  const {setContext, currentLocation, user} = useContext(AppContext)

  const {data: queryResult} = useQuery<LayoutLocationsQuery>(GET_LOCATIONS)
  const name = user?.name
  const locations: Location[] = queryResult?.locations
  const [lastLocation, setLastLocation] = usePersistedState<Location>('location')
  const [version] = usePersistedState<string>('version')

  const handleLocationSelected = useCallback(
    (item: Location): void => {
      setLastLocation(item)
      setContext((ctx) => {
        return {
          ...ctx,
          currentLocation: item,
        }
      })
    },
    [setContext, setLastLocation]
  )

  useEffect(() => {
    if (lastLocation && currentLocation?.id !== lastLocation?.id) {
      handleLocationSelected(lastLocation)
    }
  }, [currentLocation, handleLocationSelected, lastLocation])

  useEffect(() => {
    if (!lastLocation && locations?.length) {
      handleLocationSelected(locations[0])
    }
  }, [handleLocationSelected, lastLocation, locations])

  return (
    <>
      <div data-test="sidebar" className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
        <div className="flex-shrink-0 flex items-center px-4 justify-between">
          <Logo className="h-10 w-auto" />
          <span className="font-thin text-sm text-gray-500 tracking-wide self-end">{`v${version}`}</span>
        </div>
        {locations && locations.length > 1 && (
          <div className="items-center px-4 mt-4">
            <Select
              data-test="location-select"
              value="id"
              display="name"
              items={locations}
              initial={lastLocation}
              onSelection={handleLocationSelected}
            />
          </div>
        )}
        <Nav data-test="nav" user={user} />
      </div>
      <div className="w-full flex-shrink-0 flex border-t border-gray-200 p-4 overflow-hidden">
        <div className="w-full flex-shrink-0 group block focus:outline-none">
          {user ? (
            <div className="flex items-center">
              <div>
                <Avatar user={user} />
              </div>
              <div className="ml-3">
                <p className="text-base leading-6 font-medium text-gray-700 group-hover:text-gray-700">{name}</p>
                <a
                  className="flex items-center cursor-pointer text-sm leading-5 font-medium text-gray-500 group-hover:text-gray-700 group-focus:underline transition ease-in-out duration-150"
                  onClick={handleSignout}
                >
                  Sign out
                  <LogoutIconSmall className="w-3 h-3 ml-2" />
                </a>
              </div>
            </div>
          ) : (
            <div className="w-full h-full flex items-center">
              <Spinner className="h-10 w-10 m-auto" />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const MobileMenu: React.FC<{show: boolean; onClose: () => void}> = ({show, onClose, ...props}) => (
  <div {...props} className="lg:hidden">
    <Transition show={show}>
      <div className="fixed inset-0 flex z-40">
        {/* <!-- Off-canvas menu overlay, show/hide based on off-canvas menu state. --> */}
        <Transition.Child
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0" onClick={onClose}>
            <div className="absolute inset-0 bg-gray-600 opacity-75" />
          </div>
        </Transition.Child>
        {/* <!-- Off-canvas menu, show/hide based on off-canvas menu state. --> */}
        <Transition.Child
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          as="div"
          className="relative flex-1 flex flex-col max-w-xs w-full bg-white"
        >
          <MenuCloseButton onClick={onClose} />
          <Sidebar />
        </Transition.Child>
        <div className="flex-shrink-0 w-14">{/* <!-- Force sidebar to shrink to fit close icon --> */}</div>
      </div>
    </Transition>
  </div>
)

const Menu: React.FC = (props) => (
  <div {...props} className="hidden lg:flex lg:flex-shrink-0">
    <div className="flex flex-col w-64">
      <div className="flex flex-1 flex-col h-0 bg-white">
        <Sidebar />
      </div>
    </div>
  </div>
)

const Layout: React.FC = ({children}) => {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <MobileMenu data-test="mobile-menu" show={showMenu} onClose={(): void => setShowMenu(false)} />
      {!showMenu && <Menu data-test="menu" />}
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <MenuOpenButton data-test="menu-button" onClick={(): void => setShowMenu(true)} />
        <Main data-test="main">{children}</Main>
      </div>
    </div>
  )
}

export default Layout
