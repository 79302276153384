import {toTitleCase} from '../common'
import {StatMetric, Role, Status, RuleColor} from './graphql/Global'

export type User = {
  tid?: string
  id: string
  name: string
  email?: string
  picture?: string
  roles?: Role[]
}

export const hasRole = (user: User, role: Role): boolean => {
  return user?.roles?.includes(role)
}

export const hasAnyRole = (user: User, ...roles: Role[]): boolean => {
  return Boolean(roles.find((role) => hasRole(user, role)))
}

export const hasAllRoles = (user: User, ...roles: Role[]): boolean => {
  return roles.every((role) => hasRole(user, role))
}

export const isAdmin = (user: User): boolean => {
  return hasRole(user, Role.ADMIN)
}

export const isProvider = (user: User): boolean => {
  return hasAnyRole(user, Role.DOCTOR, Role.HYGIENE, Role.TREATMENT)
}

export const ROLE_NAMES: Record<Role, string> = {
  [Role.ADMIN]: 'Admin',
  [Role.DOCTOR]: 'Doctor',
  [Role.TEAM]: 'Team',
  [Role.HYGIENE]: 'Hygienist',
  [Role.TREATMENT]: 'Treatment Coordinator',
  [Role.ASSISTANT]: 'Assistant',
  [Role.OFFICE]: 'Office',
  [Role.FIRST_AVAILABLE]: 'First Available',
  [Role.NON_USER]: 'Non-user',
}

export const ROLE_DESCRIPTIONS: Record<Role, string> = {
  [Role.ADMIN]:
    'Can see all analytics, mark appointments as high priority, edit all appointments, see all individual team members stats, and manage users/tags/locations/etc.',
  [Role.DOCTOR]:
    'Can see all analytics, mark appointments as high priority, and edit all appointments that they are assigned to.',
  [Role.TEAM]: 'Can see limited analytics, edit appointments that they are assigned to, and see their own analytics.',
  [Role.HYGIENE]: 'Can see limited analytics and edit appointments that they are assigned to.',
  [Role.TREATMENT]: 'Can see limited analytics and edit appointments that they are assigned to.',
  [Role.ASSISTANT]: 'Can see limited analytics and edit appointments that they are assigned to.',
  [Role.OFFICE]: 'Can see limited analytics and edit all appointments in the queue.',
  [Role.FIRST_AVAILABLE]: 'For system use only.',
  [Role.NON_USER]: 'For system use only.',
}

export type Room = {
  id: string
  name?: string
  order?: number
  locationId?: string
  createdAt?: number
  createdById?: string
}

export type Location = {
  id: string
  name: string
  rooms: Room[]
  createdAt?: number
  createdById?: string
}

export type Tag = {
  id?: string
  name?: string
  color?: string
  level?: number
  createdAt?: number
  createdById?: string
}

export type AppointmentEvent = {
  id?: string
  status?: Status
  scheduled?: number
  length?: number
  patient?: string
  locationId?: string
  requestingId?: string
  requesting?: User
  important?: boolean
  notes?: string
  tagIds?: string[]
  tags?: Tag[]
  providerId?: string
  roomId?: string
  parentId?: string
  provider?: User
  room?: Room
  createdAt?: number
  createdById?: string
}

export type Appointment = {
  tid?: string
  id: string

  status?: Status
  scheduled?: number
  length?: number
  start?: number
  patient?: string
  locationId?: string
  requestingId?: string
  requesting?: User
  important?: boolean
  notes?: string
  tagIds?: string[]
  tags?: Tag[]
  providerId?: string
  roomId?: string
  provider?: User
  room?: Room
  lastStatusChangedAt?: number
  lastStatusBeforeCompleteChangedAt?: number
  parentId?: string

  events?: AppointmentEvent[]
  createdAt?: number
  createdById?: string

  scheduledDisplay?: string
  desiredStatus?: Status
  pendingPrompt?: boolean
  completed?: boolean
  lastDuration?: number
  totalDuration?: number
  actualStart?: number
  actualDuration?: number
}

export type S3Object = {
  bucket?: string
  key?: string
  region?: string
  uploadUrl?: string
}

export type AppointmentImport = {
  tid?: string
  id?: string

  locationId?: string

  file?: S3Object
  status?: 'WAITING' | 'PENDING' | 'COMPLETE'
  progress?: number
  importedIds?: string[]
  duplicateIds?: string[]
  skipped?: string[]
  errors?: string[]
  records?: number

  createdById?: string
  createdAt?: number
}

export interface DateRange {
  startDate: string
  endDate: string
}

export enum StatQualifier {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  CUSTOM = 'CUSTOM',
  ALL_TIME = 'ALL_TIME',
}

export type Stat = {
  tid?: string
  id?: string
  metric?: StatMetric
  date?: string
  updatedAt?: number
  value?: number
  provider?: User
  tag?: Tag
  appointments?: string[]
}

export type Rule = {
  tid?: string
  id?: string

  threshold?: number
  color?: RuleColor
  status?: Status
}

export const getRuleStyle = (rule: Rule): {backgroundColor?: string; borderColor?: string} => {
  if (!rule) return {}

  switch (rule.color) {
    case RuleColor.RED:
      return {
        backgroundColor: '#FFD7D7',
        borderColor: '#C60000',
      }
    case RuleColor.YELLOW:
      return {
        backgroundColor: '#FFF7EB',
        borderColor: '#FFEB01',
      }
    case RuleColor.GREEN:
      return {
        backgroundColor: undefined,
        borderColor: '#0FC600',
      }

    default:
      throw new Error(`Unknown RuleColor: ${rule.color}`)
  }
}

export const getRule = (rules: Rule[], seconds: number): Rule => {
  return rules
    .slice()
    .sort((a, b) => b.threshold - a.threshold)
    .find((rule) => rule.threshold === undefined || rule.threshold <= seconds)
}

export const getStatusDisplay = (status: Status): string => {
  if (!status) return undefined

  switch (status) {
    case Status.CLAIMED:
      return 'In Progress'
    case Status.PENDING:
      return 'Scheduled'

    default:
      return toTitleCase(status)
  }
}

export const getStatMetricDisplay = (metric: StatMetric): string => {
  if (!metric) return undefined

  switch (metric) {
    case StatMetric.TOTAL_PATIENTS_SERVED:
      return 'Total Patients Served'
    case StatMetric.PERCENT_SEATED_ON_TIME:
      return '% Seated On-Time'
    case StatMetric.PERCENT_SEATED_LATE:
      return '% Seated Late'
    case StatMetric.PERCENT_SEATED_VERY_LATE:
      return '% Seated Very Late'
    case StatMetric.PERCENT_COMPLETE_EARLY:
      return '% Completed Early'
    case StatMetric.PERCENT_COMPLETE_ON_TIME:
      return '% Completed On-Time'
    case StatMetric.PERCENT_COMPLETE_LATE:
      return '% Completed Late'

    default:
      return toTitleCase(metric.replace('_', ' '))
  }
}

export {Role, StatMetric, Status, RuleColor}
