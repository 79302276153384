import React, {HTMLAttributes} from 'react'

export interface SectionHeadingProps extends HTMLAttributes<HTMLElement> {
  title: string
  subtitle?: string
  description?: string
  divider?: boolean
}

export const SectionHeading: React.FC<SectionHeadingProps> = ({
  title,
  description,
  divider = true,
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={`${className} pb-5 ${
        divider && 'border-b border-gray-200'
      } space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0`}
      {...props}
    >
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        {description && <p className="mt-2 text-sm leading-5 text-gray-500">{description}</p>}
      </div>
      {children}
    </div>
  )
}
