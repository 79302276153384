import React from 'react'

export interface CheckboxProps extends React.ComponentProps<'input'> {
  onToggle: (value: boolean) => void
  description?: string
}

export const Checkbox: React.FC<CheckboxProps> = ({children, id, onToggle, description, disabled, ...props}) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onToggle(event.target.checked)
  }

  return (
    <div className={`${disabled && 'opacity-70'}`}>
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            id={id}
            type="checkbox"
            disabled={disabled}
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            {...props}
            onChange={handleOnChange}
          />
        </div>
        <div className="ml-3 text-sm leading-5">
          <label htmlFor={id} className="font-medium text-gray-700">
            {children}
          </label>
          {description && <p className="text-gray-500">{description}</p>}
        </div>
      </div>
    </div>
  )
}
