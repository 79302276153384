import React, {useEffect, useMemo, useState} from 'react'

import {CheckIconSmall, ExclamationCircleIconSmall} from '../icon'
import {useCombobox, UseComboboxState, UseComboboxStateChangeOptions} from 'downshift'
import {SelectArrows} from './select-arrows'

type IndexedItem<T extends Record<string, unknown>> = {
  item: T
  index: number
}

export interface ComboboxProps<T extends Record<string, unknown>> extends React.HTMLAttributes<HTMLElement> {
  value: string
  display?: string
  error?: string
  autoFocus?: boolean
  groups?: Record<string, (value: T) => boolean>
  visual?(value: T): React.ReactNode
  items: T[]
  initial?: T
  onSelection(value: T): void
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Combobox = <T extends Record<string, unknown>>({
  value,
  display,
  error,
  autoFocus,
  groups,
  visual,
  items,
  onSelection,
  initial,
}: ComboboxProps<T>) => {
  const groupedItems = useMemo(() => {
    if (!groups) return undefined

    return Object.keys(groups).flatMap((group) => {
      return items.filter((item) => groups[group](item))
    })
  }, [groups, items])

  const orderedItems = groups ? groupedItems : items

  const [inputItems, setInputItems] = useState(orderedItems)

  const itemToString = (item: T) => (item ? String(item[display ?? value]) : '')

  const stateReducer = (state: UseComboboxState<T>, actionAndChanges: UseComboboxStateChangeOptions<T>) => {
    const {type, changes} = actionAndChanges
    const hasInput = changes.inputValue ?? state.inputValue
    const itemFilteredOut = state.selectedItem && !inputItems.find((i) => i[value] === state.selectedItem[value])
    const highlightedIndex =
      !changes.highlightedIndex || changes.highlightedIndex === -1 ? state.highlightedIndex : changes.highlightedIndex
    const defaultIndex = Math.max(highlightedIndex, 0)

    switch (type) {
      // case useCombobox.stateChangeTypes.InputChange:
      //   return {
      //     // return normal changes.
      //     ...changes,
      //     // If input is '', clear selection
      //     ...(!hasInput && {
      //       selectedItem: undefined,
      //     }),
      //   }
      // case useCombobox.stateChangeTypes.ItemClick:
      case useCombobox.stateChangeTypes.InputKeyDownEnter:
      case useCombobox.stateChangeTypes.InputBlur:
        if (!hasInput) {
          if (highlightedIndex > -1) {
            return {
              ...changes,
              selectedItem: inputItems[changes.highlightedIndex],
              inputValue: itemToString(inputItems[changes.highlightedIndex]),
            }
          }

          return {
            ...changes,
            selectedItem: undefined,
          }
        }

        return {
          ...changes,
          // if we did not have an item selected.
          ...((!changes.selectedItem || itemFilteredOut) && {
            selectedItem: inputItems[defaultIndex],
            inputValue: inputItems.length > defaultIndex ? itemToString(inputItems[defaultIndex]) : '',
          }),
          // ...(!shouldAutoSelect && {
          //   selectedItem: undefined,
          // }),
        }
      default:
        return changes // otherwise business as usual.
    }
  }

  useEffect(() => {
    setInputItems(orderedItems)
  }, [orderedItems])

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    // getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox<T>({
    items: inputItems && inputItems.length > 0 ? inputItems : orderedItems ?? [],
    itemToString,
    stateReducer,
    onStateChange: (changes) => {
      // eslint-disable-next-line no-prototype-builtins
      if (changes.hasOwnProperty('selectedItem') && changes.selectedItem === undefined) {
        onSelection(undefined)
      }
    },
    onSelectedItemChange: (changes) => {
      onSelection(changes.selectedItem)
    },
    onInputValueChange: ({inputValue}) => {
      setInputItems(
        orderedItems.filter((item) =>
          item[display ?? value].toString().toLowerCase().includes(inputValue.toLowerCase())
        )
      )
    },
    initialSelectedItem: initial,
  })

  const getDisplay = (item: T): string => item[display ?? value]?.toString()

  const input = (
    <input
      {...getInputProps()}
      data-lpignore="true"
      autoFocus={autoFocus}
      className="block w-full truncate focus:outline-none active:outline-none"
    />
  )

  const ItemList: React.FC<{items: IndexedItem<T>[]; label?: string}> = ({items, label}) => {
    return (
      <>
        {label && items?.length > 0 && <h3 className="uppercase font-medium p-2 text-gray-600">{label}</h3>}
        {items.map((indexedItem) => (
          <li
            key={`${indexedItem.item[value]?.toString()}${indexedItem.index}`}
            className={`${
              highlightedIndex === indexedItem.index ? 'bg-secondary text-white' : 'text-gray-900'
            } cursor-default select-none relative py-2 pl-3 pr-7 hover:text-white hover:bg-secondary focus:text-white focus:bg-secondary`}
            {...getItemProps({item: indexedItem.item, index: indexedItem.index})}
          >
            <div className="flex items-center space-x-2">
              {visual && visual(indexedItem.item)}
              <span
                className={`ml-3 ${indexedItem.item === selectedItem ? 'font-semibold' : 'font-normal'} block truncate`}
              >
                {getDisplay(indexedItem.item)}
              </span>
            </div>

            {indexedItem.item === selectedItem && (
              <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                <CheckIconSmall className="h-5 w-5" />
              </span>
            )}
          </li>
        ))}
      </>
    )
  }

  return (
    <div className="space-y-1" {...getComboboxProps()}>
      <div className="relative">
        <span className="inline-block w-full rounded-md shadow-sm">
          <button
            type="button"
            {...getToggleButtonProps()}
            className={`relative w-full bg-white border rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default sm:text-sm ${
              error
                ? 'border-red-300 text-red-900 placeholder-red-300 focus-within:border-red-300 focus-within:ring-red'
                : 'border-gray-300 focus-within:outline-none focus-within:ring-1 focus-within:ring-indigo-500 focus-within:border-indigo-500'
            }`}
            aria-invalid={error ? 'true' : undefined}
          >
            {selectedItem ? (
              <div className="flex items-center space-x-2 h-5">
                {visual && visual(selectedItem)}
                {input}
              </div>
            ) : (
              <div>{input}</div>
            )}
            {error ? (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIconSmall className="h-5 w-5 text-red-500" />
              </div>
            ) : (
              <SelectArrows />
            )}
          </button>
        </span>

        <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg focus:outline-none z-50">
          <ul {...getMenuProps()}>
            {isOpen && (
              <div className="max-h-60 rounded-md py-1 text-base leading-6 ring-1 ring-black ring-opacity-10 overflow-auto sm:text-sm sm:leading-5">
                {groups ? (
                  Object.keys(groups).map((group) => (
                    <ItemList
                      key={group}
                      label={group}
                      items={inputItems
                        .filter((item) => groups[group](item))
                        .map((item) => {
                          return {item, index: inputItems.indexOf(item)}
                        })}
                    />
                  ))
                ) : (
                  <ItemList
                    items={inputItems.map((item) => {
                      return {item, index: inputItems.indexOf(item)}
                    })}
                  />
                )}
                {inputItems.length === 0 && (
                  <li className="text-gray-900 font-normal italic relative py-2 pl-3 pr-9">No match</li>
                )}
              </div>
            )}
          </ul>
        </div>

        {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
      </div>
    </div>
  )
}
