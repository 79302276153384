import React from 'react'

import {FormElement} from './form-element'
import {Combobox, ComboboxProps} from './combobox'

interface FormComboboxProps<T extends Record<string, unknown>> extends ComboboxProps<T> {
  label: string
  required?: boolean
  error?: string
  autoFocus?: boolean
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const FormCombobox = <T extends Record<string, unknown>>({
  label,
  className,
  id,
  required,
  error,
  autoFocus,
  ...props
}: FormComboboxProps<T>) => {
  return (
    <FormElement className={className} label={label} id={id} required={required}>
      <Combobox error={error} autoFocus={autoFocus} {...props} />
    </FormElement>
  )
}
