export const toDateString = (date: Date, isUTC = false): string => {
  if (isUTC) {
    return date.toISOString().split('T')[0]
  }

  const offset = date.getTimezoneOffset()
  const newDate = new Date(date.getTime() - offset * 60 * 1000)
  return newDate.toISOString().split('T')[0]
}

export const toShortDate = (iso: string): string => {
  const date = new Date(iso)
  return `${date.getUTCMonth() + 1}/${date.getUTCDate()}`
}
