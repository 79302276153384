import React from 'react'
import {Icon} from '../icon'
import Spinner from '../spinner'

export interface ButtonProps extends React.ComponentProps<'button'> {
  icon?: Icon
  size?: 'tiny' | 'small' | 'normal' | 'large'
  fullWidth?: boolean
  secondary?: boolean
  critical?: boolean
  busy?: boolean
}

// eslint-disable-next-line complexity
export const Button: React.FC<ButtonProps> = ({
  icon,
  size = 'normal',
  fullWidth = false,
  secondary = false,
  critical = false,
  busy = false,
  onClick,
  className,
  children,
  disabled,
  ...props
}) => {
  const IconType = icon
  let padding: string
  let leading: string
  let iconClass: string

  switch (size) {
    case 'tiny':
      padding = 'px-3 py-2'
      leading = 'leading-4'
      iconClass = `-ml-0.5${children && ' mr-2'} h-4 w-4`
      break
    case 'small':
      padding = 'px-4 py-2'
      leading = 'leading-5'
      iconClass = `-ml-1${children && ' mr-2'} h-5 w-5`
      break
    case 'normal':
      padding = 'px-4 py-2'
      leading = 'leading-6'
      iconClass = `-ml-1${children && ' mr-2'} h-6 w-6`
      break
    case 'large':
      padding = 'px-6 py-3'
      leading = 'leading-6'
      iconClass = `-ml-1${children && ' mr-3'} h-7 w-7`
      break

    default:
      break
  }

  const enabled = !disabled
  const iconOnly = icon && !children

  const background = iconOnly
    ? `border-none text-gray-700 sm:text-gray-500 ${
        enabled && 'hover:text-gray-800 active:text-gray-800 focus:outline-none focus:text-gray-800'
      }`
    : secondary
    ? `border-gray-300 bg-white text-gray-700 ${
        enabled &&
        'hover:text-gray-600 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 active:text-gray-800 active:bg-gray-100'
      }`
    : critical
    ? `border-transparent text-red-800 bg-red-200 ${
        enabled &&
        'hover:bg-red-50 focus:outline-none focus:border-red-300 focus:ring-1 focus:ring-red active:bg-red-200'
      }`
    : `border-transparent bg-indigo-600 text-white ${
        enabled && 'hover:bg-indigo-500 focus:border-indigo-700 focus:ring-1 focus:ring-indigo-500 active:bg-indigo-700'
      }`

  return (
    <span
      className={`flex rounded-md ${!iconOnly && 'shadow-sm'} ${
        fullWidth ? 'justify-center w-full' : 'items-center'
      } ${className}`}
    >
      <button
        type="button"
        className={`inline-flex ${fullWidth ? 'justify-center w-full' : 'items-center'} justify-center w-full ${
          iconOnly ? 'p-1' : padding
        } border ${background} text-sm ${leading} font-medium rounded-md focus:outline-none transition ease-in-out duration-150 ${
          disabled && 'opacity-50 cursor-not-allowed'
        }`}
        disabled={disabled}
        onClick={onClick}
        {...props}
      >
        {busy && <Spinner className="h-5 w-5 mr-2" />}
        {icon && <IconType className={iconClass} />}
        {children}
      </button>
    </span>
  )
}
