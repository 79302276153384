import React from 'react'
import {ExclamationCircleIconSmall} from '../icon'
import {FormElement} from './form-element'

export interface FormInputProps extends React.ComponentProps<'input'> {
  label?: string
  hideLabel?: boolean
  required?: boolean
  error?: string
}

export const FormInput: React.FC<FormInputProps> = ({
  label,
  hideLabel,
  id,
  className,
  required,
  error,
  disabled,
  ...props
}) => (
  <FormElement className={className} label={label} hideLabel={hideLabel} id={id} required={required}>
    <div>
      <div className="relative rounded-md shadow-sm">
        <input
          type="text"
          id={id}
          data-lpignore="true"
          disabled={disabled}
          className={`border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm block w-full transition duration-150 ease-in-out ${
            error && 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red'
          } ${disabled && 'opacity-70 cursor-not-allowed'}`}
          aria-invalid={error ? 'true' : undefined}
          aria-describedby={error ? `${label}-error` : undefined}
          {...props}
        />
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIconSmall className="h-5 w-5 text-red-500" />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${label}-error`}>
          {error}
        </p>
      )}
    </div>
  </FormElement>
)
