import React from 'react'
import {FormElement} from './form-element'

export interface FormTextareaProps extends React.ComponentProps<'textarea'> {
  label: string
  required?: boolean
  error?: string
}

export const FormTextarea: React.FC<FormTextareaProps> = ({
  label,
  id,
  className,
  required,
  error,
  disabled,
  ...props
}) => (
  <FormElement className={className} label={label} id={id} required={required}>
    <div className="rounded-md shadow-sm">
      <textarea
        id={id}
        rows={2}
        disabled={disabled}
        className={`shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md ${
          disabled && 'opacity-70 cursor-not-allowed'
        }`}
        {...props}
      />
    </div>
    {error && (
      <p className="mt-2 text-sm text-red-600" id={`${label}-error`}>
        {error}
      </p>
    )}
  </FormElement>
)
